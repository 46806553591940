<div
  (ccResize)="handleDropdownResize($event)"
  [cdkTrapFocus]="!hasChildDropdownActive$() && !fakeFocus"
  [class.cc-dropdown--shadow]="shadow"
  [style.max-height]="maxHeight"
  class="cc-dropdown"
>
  <ng-scrollbar cdkScrollable>
    <div
      #dropdownContent
      [style.width]="width"
      class="cc-dropdown__content"
    >
      <ng-content></ng-content>
    </div>
  </ng-scrollbar>
</div>

<div
  #dropdownTrigger
  class="cc-dropdown-action"
>
  <div class="cc-dropdown-action__leading">
    @if (selectable) {
      <i
        [class.cc-dropdown-action__leading-select-indicator--selected]="selected"
        class="icon-check cc-dropdown-action__leading-select-indicator"
      ></i>
    }

    @if (leadingIcon) {
      <i
        [class]="leadingIcon"
        [style.color]="leadingIconColor"
      ></i>
    }

    <ng-content select="[slot='leading']"></ng-content>
  </div>

  <div class="cc-dropdown-action__main">
    <div class="cc-dropdown-action__main-title">
      <ng-content select="[slot='title']"></ng-content>
    </div>

    <div class="cc-dropdown-action__main-caption">
      <ng-content select="[slot='caption']"></ng-content>
    </div>
  </div>

  <div class="cc-dropdown-action__trailing">
    @if (trailingIcon) {
      <i [class]="trailingIcon"></i>
    }

    <ng-content select="[slot='trailing']"></ng-content>

    @if (childDropdown) {
      <i
        [class.cc-dropdown-action__trailing-child-dropdown-toggle--expanded]="childDropdownVisible$()"
        class="cc-dropdown-action__trailing-child-dropdown-toggle icon-chevronDown"
      ></i>
    }
  </div>
</div>

<ng-template
  (attach)="handleChildDropdownAttach()"
  (detach)="handleChildDropdownDetach()"
  (overlayOutsideClick)="childDropdownVisible$.set(getOverlayVisibilityAfterOutsideClick($event, dropdownTrigger))"
  [cdkConnectedOverlayOpen]="childDropdownVisible$()"
  [cdkConnectedOverlayOrigin]="dropdownTrigger"
  [cdkConnectedOverlayPositions]="childDropdownPositionStrategy"
  cdkConnectedOverlay
>
  <ng-content select="cc-dropdown"></ng-content>
</ng-template>

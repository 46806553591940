import { Directive, ElementRef, inject, NgZone, type OnDestroy, type OnInit, output } from '@angular/core';

export class ResizeEvent {
  public newRect: DOMRectReadOnly;
  public oldRect?: DOMRectReadOnly;
  public isFirst: boolean;

  public constructor(newRect: DOMRectReadOnly, oldRect: DOMRectReadOnly | undefined) {
    this.newRect = newRect;
    this.oldRect = oldRect;
    this.isFirst = oldRect == null;
  }
}

@Directive({
  selector: '[ccResize]',
  standalone: true,
})
export class ResizeDirective implements OnInit, OnDestroy {
  readonly resize = output<ResizeEvent>({ alias: 'ccResize' });

  private oldRect?: DOMRectReadOnly;

  private readonly elementRef: ElementRef = inject(ElementRef);
  private readonly zone: NgZone = inject(NgZone);

  private readonly observer: ResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
    this.zone.run(() => {
      this.observe(entries);
    });
  });

  ngOnInit(): void {
    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private observe(entries: ResizeObserverEntry[]): void {
    const [domSize] = entries;
    const resizedEvent = new ResizeEvent(domSize.contentRect, this.oldRect);
    this.oldRect = domSize.contentRect;
    this.resize.emit(resizedEvent);
  }
}
